import { computed, ref, useRoute, useContext } from '@nuxtjs/composition-api';
import type { ItemsInterface } from '@cellar-services/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const bbxStore = () => {
  const context = useContext();
  const customerStore = useCustomerStore();
  const route = useRoute();

  // State
  const items = ref<ItemsInterface>({});
  const aggregations = ref({});
  const isLoading = ref(false); // both asynchronous & synchronous loading
  const isBlockingLoading = ref(false); // synchronous loading
  const hasLoaded = ref(false);
  const pageSize = ref<number>(Number(route.value.query?.pageSize) || 24);
  const totalItems = ref(0);
  const totalPages = ref(0);
  const currentPage = ref<number>(Number(route.value.query?.currentPage) || 1);
  const filters = ref([]);
  const activeRows = ref<number[]>([]);

  // Getters
  const isEmpty = computed(() => Object.keys(items.value).length === 0);
  const areAggregationsEmpty = computed(() => Object.keys(aggregations.value).length === 0);
  const uniqueID = computed(() => customerStore.sapUniqueID);
  const defaultID = computed(() => customerStore.sapDefaultID);
  const linkedAccounts = computed(() => customerStore.sapLinkedAccounts);

  const shouldTableSkeletonRender = computed(
    () => isEmpty.value || isBlockingLoading.value || (isLoading.value && isEmpty.value)
  );

  const paginationInfo = computed(() => context.app.i18n.t('Showing {0}-{1} of {2} items', [
    itemsRange.value.start,
    itemsRange.value.end,
    totalItems.value,
  ]));

  const itemsRange = computed(() => {
    let end = currentPage.value * pageSize.value;
    const start = end - pageSize.value + 1;

    if (end > totalItems.value) end = totalItems.value;

    return { start, end };
  });

  const queryParams = computed(() => {
    const object = { currentPage: currentPage.value, pageSize: pageSize.value };

    for (const filter of filters.value) {
      object[filter.code] = filter.value;
    }

    if (activeRows.value.length) {
      object['active'] = activeRows.value.toString();
    }

    return object;
  });

  const graphQueryParams = computed(() => {
    const object = { currentPage: currentPage.value, pageSize: pageSize.value };

    if (filters.value.length) {
      object['filter'] = {};
    }

    for (const filter of filters.value) {
      object['filter'][filter.code] = { in: [filter.value] };
    }

    return object;
  });

  // Actions
  const setItems = (value: ItemsInterface) => (items.value = value);
  const setAggregations = (value) => (aggregations.value = value);
  const setIsBlockingLoading = (value) => (isBlockingLoading.value = value);
  const resetActiveRows = () => activeRows.value = [];

  const toggleRow = (row: number) => {
    var index = activeRows.value.indexOf(row);

    if (index === -1) {
      activeRows.value.push(row);
    } else {
      activeRows.value.splice(index, 1);
    }
  };

  const closeRow = (row: number) => activeRows.value.splice(activeRows.value.indexOf(row), 1);

  const setItem = (itemID, item) => {
    if (items.value[itemID]) {
      items.value[itemID] = { ...items.value[itemID], ...item };
    }
  };

  const setIsLoading = (value: boolean) => {
    isLoading.value = value;

    if (value === false) {
      hasLoaded.value = true;
    }
  };

  const setTotalPages = (value: number) => (totalPages.value = value);
  const setTotalItems = (value: number) => (totalItems.value = value);

  const setCurrentPage = (context, page: number) => {
    currentPage.value = page;
  };

  const setPageSize = (context, size: number) => {
    pageSize.value = size;
  };

  const clearFilters = () => {
    filters.value = [];
  };

  const setFilter = async (filter) => {
    filters.value = [filter];
  };

  const resetPagination = () => {
    currentPage.value = 1;
    pageSize.value = 24;
  };

  const resetStore = () => {
    setItems({});
    setAggregations({});
    setIsLoading(false);
    setIsBlockingLoading(false);
    setTotalItems(0);
    setTotalPages(0);
    resetPagination();
    resetActiveRows();
    clearFilters();

    hasLoaded.value = false;
  }

  return {
    items,
    aggregations,
    isLoading,
    isBlockingLoading,
    hasLoaded,
    filters,
    isEmpty,
    areAggregationsEmpty,
    queryParams,
    graphQueryParams,
    uniqueID,
    defaultID,
    linkedAccounts,
    setItems,
    setItem,
    setAggregations,
    setIsBlockingLoading,
    setIsLoading,
    pageSize,
    totalItems,
    totalPages,
    currentPage,
    itemsRange,
    setCurrentPage,
    setPageSize,
    setTotalItems,
    setTotalPages,
    clearFilters,
    setFilter,
    resetPagination,
    activeRows,
    resetActiveRows,
    toggleRow,
    closeRow,
    shouldTableSkeletonRender,
    paginationInfo,
    resetStore,
  };
};
