import { formatAttributes, formatAggregations } from '@cellar-services/composables/helpers';
import { customerBidsReceivedQuery } from '@cellar-services/queries/getCustomerBidsReceived.gql';
import type { CustomerCellarResponse, AcceptedBidInterface } from '@cellar-services/types';
import { useBidsReceivedStore } from '@cellar-services/stores/bbx';
import { useQueryParams } from '~/bbrTheme/composables';
import { useContext, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useApi, useUiNotification } from '~/composables';
import { storeToRefs } from 'pinia';

/**
 * BBX bid actions
 */
// @TODO ALGOLIA: REMOVE MAGENTO RELATED LOGIC WHEN NEW SOLUTION IS IMPLEMENTED
export const useBBXBidsReceived = () => {
  const rejectBidPending = ref(false);

  const context = useContext();
  const { query } = useApi();
  const { setQueryParams } = useQueryParams();
  const { send: sendNotification } = useUiNotification();

  const bidsReceivedStore = useBidsReceivedStore();
  const { graphQueryParams, pageSize, queryParams } = storeToRefs(bidsReceivedStore);

  const {
    setItems,
    setIsLoading,
    setTotalItems,
    setTotalPages,
    setAggregations,
    setPageSize,
    setCurrentPage,
    setIsBlockingLoading,
    resetStore,
  } = bidsReceivedStore;

  const acceptBid = async (payload): Promise<AcceptedBidInterface> =>
    await context.$vsf.$cellarServices.api.acceptBid(payload);

  const rejectBid = async (payload): Promise<any> => {
    rejectBidPending.value = true;

    await context.$vsf.$cellarServices.api.rejectBid(payload).then((res) => {
      payload.bid_ids.forEach((bid_id) => {
        if (res[bid_id]
          && res[bid_id].http_status_code >= 200
          && res[bid_id].http_status_code < 300
        ) {
          sendNotification({
            id: Symbol('bid_rejected'),
            message: context.app.i18n.t('You have successfully rejected the bids.') as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: context.app.i18n.t('Bids rejected') as string,
          });
        } else {
          sendNotification({
            id: Symbol('bid_rejected'),
            message: res[bid_id].error_message,
            type: 'danger',
            icon: 'danger',
            persist: false,
            title: context.app.i18n.t('Error') as string,
          });
        }
      });
    }).finally(() => {
      rejectBidPending.value = false;
    });
  };

  const getBidsReceived = async (): Promise<any> => {
    const items = {};

    try {
      setIsLoading(true);

      const response = await query<CustomerCellarResponse>(customerBidsReceivedQuery, graphQueryParams.value);

      if (response.errors || !response.data) {
        setIsLoading(false);

        Logger.error(response.errors);

        return;
      }

      // TODO: swap to response.data.customer.listings with the BBX listings query
      const data = response.data.customer.cellar_bid[0];

      if (!data) {
        resetStore();

        return;
      }

      const totalItems = data.total_count;
      const aggregations = formatAggregations(data.aggregations);
      const pageInfo = data.page_info;

      for (const item of data.items) {
        const attributes = formatAttributes(item.attributes_value);

        item.internal_id = item.item_id;

        items[item.internal_id] = {
          ...item,
          ...attributes,
        };
      }

      setItems(items);
      setTotalItems(totalItems);
      setTotalPages(pageInfo.total_pages);
      setCurrentPage(context, pageInfo.current_page);
      setPageSize(context, pageInfo.page_size);

      /**
       * Set aggregations (used for filters in the overview page) only when the query isn't filtered
       * as a filtered query returns aggregations only for the filtered subset items.
       */
      if (!('filter' in graphQueryParams.value)) {
        setAggregations(aggregations);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
  const setPage = async (newPage: number, newSize: number) => {
    // Blocks UI while loading
    setIsBlockingLoading(true);

    // Reset page to 1 if page size was changed
    setCurrentPage(context, newSize !== pageSize.value ? 1 : newPage);
    setPageSize(context, newSize);

    await getBidsReceived();

    // Set URL params with latest query params
    setQueryParams(queryParams.value);

    setIsBlockingLoading(false);
  };

  return {
    acceptBid,
    rejectBid,
    rejectBidPending,
    getBidsReceived,
    setPage
  };
};
