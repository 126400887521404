import { Context as NuxtContext } from '@nuxt/types';
import { merge } from 'lodash-es';
import { Logger } from '~/helpers/logger';

export type ApiClientMethod = (...args: any) => Promise<any>;

interface CreateProxiedApiParams {
  nuxtCtx: NuxtContext;
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

export const createProxiedApi = ({
  givenApi, client, tag, nuxtCtx,
}: CreateProxiedApiParams) => new Proxy(givenApi, {
  get: (target, prop, receiver) => {
    const functionName = String(prop);

    if (Reflect.has(target, functionName)) {
      return Reflect.get(target, prop, receiver);
    }

    return async (...args) => {
      try {
        const response = await client.post(`/${tag}/${functionName}`, args);

        return response.data;
      } catch (error) {
        // TODO: come up with a better solution to handle errors on API calls
        // This is a temporary solution
        const errorCode = error.response ? error.response.status : 'Unknown';
        const errorMessage = error.response ? error.response.data : error.message || 'An error occurred';

        Logger.error(`API Error in ${tag}/${functionName}:`, {
          code: errorCode,
          message: errorMessage,
        });

        return { error: true, code: errorCode, message: errorMessage };
      }
    };
  },
});

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  const cookie = getCookies(context);
  const initialConfig = merge({
    axios: {
      headers: {
        ...(cookie ? { cookie } : {}),
      },
    },
  }, configuration);

  return initialConfig;
};
