

















import {
  defineComponent,
  computed,
  toRef,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Errors',
  props: {
    errors: {
      type: Object,
      required: true,
    },
    heading: {
      type: String,
      default: 'There is a problem',
    },
  },
  setup(props) {
    const propErrors = toRef(props, 'errors');

    const formattedErrors = computed(() => {
      const data = [];

      for (const key in propErrors.value) {
        if (propErrors.value[key][0]) {
          data.push(propErrors.value[key][0]);
        }
      }

      return data;
    });

    return {
      formattedErrors,
    }
  },
});
