import { render, staticRenderFns } from "./QuickAccessModal.vue?vue&type=template&id=8a6e1fae&scoped=true"
import script from "./QuickAccessModal.vue?vue&type=script&lang=ts"
export * from "./QuickAccessModal.vue?vue&type=script&lang=ts"
import style0 from "./QuickAccessModal.vue?vue&type=style&index=0&id=8a6e1fae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a6e1fae",
  null
  
)

export default component.exports