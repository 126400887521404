import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import { ATTRIBUTE_CODE_MATURITY, SORTED_MATURITY } from '~/bbrTheme/modules/catalog/constants/attributes/maturity';

// @TODO ALGOLIA: REMOVE MAGENTO RELATED LOGIC WHEN NEW SOLUTION IS IMPLEMENTED
const CELLAR_AGGREGATIONS = [ATTRIBUTE_CODE_MATURITY, 'colour', 'family_type', 'stored_status'];
const SORT_ORDER = {
  [ATTRIBUTE_CODE_MATURITY]: SORTED_MATURITY,
  colour: ['Red', 'White', 'Rosé'],
  family_type: [FamilyType.Spirits],
  stored_status: ['Duty paid', 'In bond', 'Lying abroad'],
};

export const formatAttributes = (attributes) => {
  const formattedAttributes = {};

  for (const { code, value } of attributes) {
    formattedAttributes[code] = value;
  }

  return formattedAttributes;
};

export const formatAggregations = (aggregations) => {
  const formattedAggregations = {};
  let colourTotal = 0;
  let colourWithSpiritTotal = 0;

  for (const { attribute_code, options } of aggregations) {
    if (CELLAR_AGGREGATIONS.includes(attribute_code)) {
      formattedAggregations[attribute_code] = options;
    }
  }

  // TODO: Remove once GraphQL returns stored_status as an aggregation
  if (!('stored_status' in formattedAggregations)) {
    formattedAggregations['stored_status'] = [
      {
        count: 1000,
        label: 'Duty paid',
        value: '114',
        __typename: 'AggregationOption',
        fraction: '0.2000',
        code: 'stored_status',
        fraction_wines: '0.2000',
      },
      {
        count: 1000,
        label: 'In bond',
        value: '115',
        __typename: 'AggregationOption',
        fraction: '0.2000',
        code: 'stored_status',
        fraction_wines: '0.2000',
      },
      {
        count: 3000,
        label: 'Lying abroad',
        value: '116',
        __typename: 'AggregationOption',
        fraction: '0.4000',
        code: 'stored_status',
        fraction_wines: '0.4000',
      },
    ];
  }

  for (const code in formattedAggregations) {
    let total = 0;

    const aggregation = formattedAggregations[code].sort((a, b) => {
      const sortOrder = SORT_ORDER[code];

      return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
    });

    // Total items in all buckets of a single aggregation
    for (const bucket of aggregation) {
      total += bucket.count;
    }

    if (code === 'colour') {
      colourTotal = total;
      colourWithSpiritTotal = total;
    }

    // Fraction of bucket count by total count
    for (const bucket of aggregation) {
      bucket.fraction = Number(bucket.count / total).toFixed(4);
      bucket.code = code;
    }
  }

  // Set colour options as the type options, so that spirits can be added if present
  formattedAggregations['type'] = formattedAggregations['colour'];

  const spiritOption = formattedAggregations['family_type']?.find((option) => option.label === FamilyType.Spirits);

  if (spiritOption) {
    colourWithSpiritTotal = colourTotal + spiritOption.count;

    if (!Array.isArray(formattedAggregations['type'])) {
      formattedAggregations['type'] = [];
    }

    formattedAggregations['type'].push(spiritOption);
  }

  // Fraction of bucket count by total count
  for (const bucket of formattedAggregations['type']) {
    // Fraction with spirits
    bucket.fraction = Number(bucket.count / colourWithSpiritTotal).toFixed(4);

    // Fraction without spirits
    bucket.fraction_wines = Number(bucket.count / colourTotal).toFixed(4);
  }

  return formattedAggregations;
};
