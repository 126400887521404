import { formatAttributes, formatAggregations } from '@cellar-services/composables/helpers';
import { customerBidsPlacedQuery } from '@cellar-services/queries/getCustomerBidsPlaced.gql';
import type { CustomerBidsResponse } from '@cellar-services/types';
import { useBidsPlacedStore } from '@cellar-services/stores/bbx';
import { useQueryParams } from '~/bbrTheme/composables';
import { useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useApi } from '~/composables';
import { storeToRefs } from 'pinia';

/**
 * BBX bid actions
 */
// @TODO ALGOLIA: REMOVE MAGENTO RELATED LOGIC WHEN NEW SOLUTION IS IMPLEMENTED
export const useBBXBidsPlaced = () => {
  const context = useContext();
  const { query } = useApi();
  const { setQueryParams } = useQueryParams();

  const bidsPlacedStore = useBidsPlacedStore();
  const { graphQueryParams, pageSize, queryParams } = storeToRefs(bidsPlacedStore);

  const {
    setItems,
    setIsLoading,
    setTotalItems,
    setTotalPages,
    setAggregations,
    setPageSize,
    setCurrentPage,
    setIsBlockingLoading,
    resetStore,
  } = bidsPlacedStore;

  const getBidsPlaced = async (): Promise<any> => {
    const items = {};

    try {
      setIsLoading(true);

      const response = await query<CustomerBidsResponse>(customerBidsPlacedQuery, graphQueryParams.value);

      if (response.errors || !response.data) {
        setIsLoading(false);

        Logger.error(response.errors);

        return;
      }

      const data = response.data.customer.bids[0];

      if (!data) {
        resetStore();

        return;
      }

      const totalItems = data.total_count;
      const aggregations = formatAggregations(data.aggregations);
      const pageInfo = data.page_info;

      for (const item of data.items) {
        const attributes = formatAttributes(item.attributes_value);

        item.internal_id = item.bid_id;

        items[item.internal_id] = {
          ...item,
          ...attributes,
        };
      }

      setItems(items);
      setTotalItems(totalItems);
      setTotalPages(pageInfo.total_pages);
      setCurrentPage(context, pageInfo.current_page);
      setPageSize(context, pageInfo.page_size);

      /**
       * Set aggregations (used for filters in the overview page) only when the query isn't filtered
       * as a filtered query returns aggregations only for the filtered subset items.
       */
      if (!('filter' in graphQueryParams.value)) {
        setAggregations(aggregations);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const placeBid = async (payload): Promise<any> => await context.$vsf.$cellarServices.api.placeBid(payload);

  const setPage = async (newPage: number, newSize: number) => {
    // Blocks UI while loading
    setIsBlockingLoading(true);

    // Reset page to 1 if page size was changed
    setCurrentPage(context, newSize !== pageSize.value ? 1 : newPage);
    setPageSize(context, newSize);

    await getBidsPlaced();

    // Set URL params with latest query params
    setQueryParams(queryParams.value);

    setIsBlockingLoading(false);
  };

  return {
    getBidsPlaced,
    placeBid,
    setPage
  };
};
